<template>
  <div class="container">
    <!-- 物流信息-->
    <!-- <div class="content-item" v-show="oderList.completeStatus == 120">
      <div class="add-box">
        <div class="add-bg"></div>
        <span>回寄物流信息</span>
      </div>
      <div class="info">
        <span>物流公司：{{ oderList.deliveryCompany }}</span>
        <span>物流单号：{{ oderList.deliveryNo}}<span class="copy-btn" @click="copyContent(oderList.deliveryNo)">复制</span></span>
      </div>
    </div> -->
    <!-- 申请信息-->
    <div class="content-item">
      <div class="reject-box" v-show="oderList.completeStatus == 150">
        <img src="@/assets/images/red-info.svg" alt="" />
        <span>驳回原因：{{ oderList.reason }}</span>
      </div>

      <div class="add-box">
        <div class="add-bg"></div>
        <span>申请信息</span>
      </div>
      <div class="info">
        <span>申请编号：{{ oderList.applySn }}</span>
        <span>申请时间：{{ oderList.createTime }}</span>
        <span>申请来源：{{ getLabelByValue(oderList.applyType, applyTypeList)}}</span>
        <span :title="oderList.applyCause">申请理由: {{ oderList.applyCause }}</span>
      </div>
      <div class="info">
        <span>关联客户：{{ oderList.associatedCustomer }}</span>
        <span>售后类型：{{ getLabelByValue(oderList.refundType, typeOptions) }}</span>
        <span>业务类型：{{ getLabelByValue(oderList.operationType, operationTypeList) }}</span>
      </div>
      <div class="status-info" v-show="oderList.completeStatus == 100">待审核</div>
      <div class="status-info" v-show="oderList.completeStatus == 110">待回寄</div>
      <div class="status-info" v-show="oderList.completeStatus == 120">待入库</div>
      <div class="status-info red-info" v-show="oderList.completeStatus == 150">审核驳回</div>
    </div>

    <!-- 配送方式-->
    <div class="content-item">
      <div class="add-box">
        <div class="add-bg"></div>
        <span>配送方式</span>
      </div>
      <div class="info">
        <span>配送方式：{{ oderList.distributionMode=='1'?'上门取货':'自行回寄'  }}</span>
        <span v-show="oderList.completeStatus == 110">物流公司：<el-input v-model="oderList.deliveryCompany" placeholder="请输入物流公司" clearable></el-input></span> 
        <span v-show="oderList.completeStatus == 110">物流单号：<el-input v-model="oderList.deliveryNo" placeholder="请输入物流单号" clearable></el-input></span>
      </div>
    </div>

    <!-- 订单信息-->
    <div class="content-item">
      <div class="add-box">
        <div class="add-bg"></div>
        <span>订单信息</span>
      </div>
      <div class="info">
        <span>订单状态(申请时)：
          <div class="status">
            {{ getLabelByValue(oderList.mallOrderRefundResDTO.status, statusOptions) }}
          </div></span>
        <span>订单编号：{{ oderList.mallOrderRefundResDTO.orderSn }}</span>
        <span>订单来源：{{ oderList.mallOrderRefundResDTO.orderSource }}</span>
        <span>下单时间：{{ oderList.mallOrderRefundResDTO.createTime }}</span>
      </div>
    </div>

    <!-- 支付方式-->
    <div class="content-item">
      <div class="add-box">
        <div class="add-bg"></div>
        <span>支付方式</span>
      </div>
      <div class="info">
        <span>支付单号：{{ oderList.mallOrderRefundResDTO.paySn }}</span>
        <span>支付时间：{{ oderList.mallOrderRefundResDTO.paymentTime }}</span>
      </div>
      <div class="info">
        <span>支付方式：{{ oderList.mallOrderRefundResDTO.payTypeName }}</span>
        <span>支付金额：{{ oderList.totalAmount }}</span>
      </div>
    </div>
    <!-- 退货商品信息-->
    <div class="content-item">
      <div class="add-box">
        <div class="add-bg"></div>
        <span>确认退货商品信息</span>
      </div>
      <el-table :data="oderList.orderItems" height="350px" :header-cell-style="{background:'#F5F5F5'}">
        <el-table-column label="商品" width="350" align="center">
          <template slot-scope="scope">
            <Detail-Card :rows="scope.row"> </Detail-Card>
          </template>
        </el-table-column>
        <el-table-column label="退货同行单" align="center" key="chkdjbh" prop="chkdjbh" width="auto" show-overflow-tooltip />
        <el-table-column label="批号" align="center" key="pchao" prop="pchao" width="auto" show-overflow-tooltip />
        <el-table-column label="单价(元)" align="center" key="salesPrice" prop="salesPrice" width="auto"/>
        <el-table-column label="原小计" align="center" key="total" width="auto">
          <template slot-scope="scope">
            <div>{{(scope.row.productQuantity * scope.row.salesPrice).toFixed(2)}}</div>
          </template>
        </el-table-column>
        <el-table-column label="下单数量" align="center" key="productQuantity" prop="productQuantity" width="auto" :show-overflow-tooltip="true"/>
        <el-table-column label="预计退货数量" align="center" key="refundProductQuantity" prop="refundProductQuantity" width="auto" :show-overflow-tooltip="true"/>
        <el-table-column label="实际退货数量" align="center" key="actualProductQuantity" prop="actualProductQuantity" width="auto" :show-overflow-tooltip="true"/>
        <el-table-column label="预计退货小计(元)" align="center" key="tTotal" width="auto">
          <template slot-scope="scope">
            <span>{{(scope.row.refundProductQuantity * scope.row.salesPrice).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/account-empty.png" alt="" />
        </template>
      </el-table>
      <!-- <div class="bottom-box">
        <div>
          <span>实际退回总数：</span>
          <span class="bottom-num">{{ oderList.totalPackNum }}</span>
        </div>
        <div>
          <span>实际回退慧金豆：</span>
          <span class="bottom-num">￥{{ oderList.totalAmount }}</span>
        </div>
        <div>
          <span class="black">实际抵消账期：</span>
          <span class="bottom-num black bold">￥{{ oderList.payAmount || 0 }}</span>
        </div>
      </div> -->

      <!-- 退款相关信息-->
      <div class="content-item">
        <div class="add-box">
          <div class="add-bg"></div>
          <span>退款方式及对应金额</span>
        </div>
        <div class="info">
          <span>退款方式：{{ oderList.refundWay == '0' ? '慧金豆' : '账期' }}</span>
          <span v-if="oderList.operationType == '2'">实际补差总数：{{ oderList.compensationNUm }}</span>
          <span v-else-if="oderList.operationType == '3'">实际调价总数：{{ oderList.adjustPriceNUm }}</span>
          <span v-else>实际退货总数：{{ oderList.totalQuantity }}</span>

          <span v-if="oderList.operationType == '2'">实际补差总金额：{{ oderList.compensationAmt }}</span> 
          <span v-else-if="oderList.operationType == '3'">实际调价总金额：{{ oderList.adjustPriceAmt }}</span>
          <span v-else>实际退货总金额：{{ oderList.totalAmt }}</span>
        </div>

        <span v-show="oderList.completeStatus == 110" class="save-btn" @click="saveDelivery">确认回寄</span>
      </div>

      <!-- <div class="operation-box" v-show="oderList.completeStatus == 100">
        <span @click="rejectSalesReturn">驳回</span>
        <span class="agree-btn" @click="agreeSalesReturn">同意</span>
      </div>
      <div class="operation-box" v-show="oderList.completeStatus == 130">
        <span class="agree-btn" @click="rechargeFun">确定充值</span>
        <span @click="backList">取消</span>
      </div>
      <div class="operation-box" v-show="oderList.completeStatus == 120">
        <span @click="changePriceFun">保存修改</span>
        <span @click="backList">取消</span>
      </div>
      <div
        class="operation-box"
        v-show="
          oderList.completeStatus == 110 ||
          oderList.completeStatus == 140 ||
          oderList.completeStatus == 150
        "
      >
        <span @click="backList">返回列表</span>
      </div> -->
    </div>
  </div>
</template>
 
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import DetailCard from "@/components/custom-base/custom-product-detail-card";
import { returnProduct } from "@/request/Orders/order";
import { getLabelByValue } from '@/utils/tool';
export default {
  components: {
    Breadcrumb,
    DetailCard
  },
  watch: {
    $route: {
      handler(newVal, olVal) {
        console.log(newVal)
        if (newVal.params.mallOrderItemResDTOS) {
          localStorage.setItem("AfterSeles", JSON.stringify(newVal.params));
          this.oderList = newVal.params;
          this.oderList.orderItems = newVal.params.mallOrderItemResDTOS;
          this.oderList.order = newVal.params.mallOrderRefundResDTO;
          this.deliveryCompany = newVal.params.deliveryCompany;
          this.deliveryNo = newVal.params.deliveryNo;
          this.completeStatus = newVal.params.completeStatus;
          this.applyId = newVal.params.id;
          switch (this.completeStatus) {
            case 100:
              this.statusName = "待审核";
              break;
            case 110:
              this.statusName = "待回寄";
              break;
            case 120:
              this.statusName = "待入库验收";
              break;
            case 130:
              this.statusName = "待充值";
              break;
            case 140:
              this.statusName = "已充值";
              break;
            case 150:
              this.statusName = "审核驳回";
              break;
          }
          switch (newVal.params.mallOrderRefundResDTO.status) {
            case 100:
              this.orderName = "待付款";
              break;
            case 110:
              this.orderName = "支付中";
              break;
            case 120:
              this.orderName = "支付中";
              break;
            case 130:
              this.orderName = "部分发货";
              break;
            case 140:
              this.orderName = "待收货";
              break;
            case 150:
              this.orderName = "部分收货";
              break;
            case 160:
              this.orderName = "已完成";
              break;
            case 170:
              this.orderName = "已关闭";
              break;
          }
          //申请状态： 待审核-100 待回寄-110 待入库验收-120 待充值-130 已充值-140 审核驳回-150
        } else {
          this.Local = JSON.parse(localStorage.getItem("AfterSeles"));
          this.oderList = this.Local;
          this.oderList.orderItems = this.Local.mallOrderItemResDTOS;
          this.oderList.order = this.Local.mallOrderRefundResDTO;
          this.deliveryCompany = this.Local.deliveryCompany;
          this.deliveryNo = this.Local.deliveryNo;
          this.completeStatus = this.Local.completeStatus;
          this.applyId = this.Local.id;
          switch (this.completeStatus) {
            case 100:
              this.statusName = "待审核";
              break;
            case 110:
              this.statusName = "待回寄";
              break;
            case 120:
              this.statusName = "待入库验收";
              break;
            case 130:
              this.statusName = "待充值";
              break;
            case 140:
              this.statusName = "已充值";
              break;
            case 150:
              this.statusName = "审核驳回";
              break;
          }
          switch (this.Local.mallOrderRefundResDTO.status) {
            case 100:
              this.orderName = "待付款";
              break;
            case 110:
              this.orderName = "支付中";
              break;
            case 120:
              this.orderName = "支付中";
              break;
            case 130:
              this.orderName = "部分发货";
              break;
            case 140:
              this.orderName = "待收货";
              break;
            case 150:
              this.orderName = "部分收货";
              break;
            case 160:
              this.orderName = "已完成";
              break;
            case 170:
              this.orderName = "已关闭";
              break;
          }
          //申请状态： 待审核-100 待回寄-110 待入库验收-120 待充值-130 已充值-140 审核驳回-150
        }
        this.orderId = newVal.params.id;
      },
      immediate: true,
    },
  },
  created() {},
  beforeDestroy() {
    localStorage.removeItem("AfterSeles");
  },
  data() {
    return {
      getLabelByValue,
      Local: null,
      Aftersales: 1,
      //寄回填写物流公司
      deliveryCompany: "",
      //寄回填写物流单号
      deliveryNo: "",
      //寄回状态
      completeStatus: 0, //申请状态： 待审核-100 待回寄-110 待入库验收-120 待充值-130 已充值-140 审核驳回-150
      orderId: "",
      orderName: "",
      statusName: "全部",
      status: 0, //订单状态：-1全部 待付款-100 支付中-110 支付中-120 部分发货-130 待收货-140 部分收货-150 已完成-160 已关闭-170
      oderList: {
        order: { endtime: "", payEndTime: null },
      },
      applyTypeList: [
        {label:"平台申请", value:1},
        {label:"客户申请", value:2},
        {label:"ERP出库自动申请", value:3},
        {label:"erp申请", value:4}
      ],
      typeOptions:[
        {label:"补差", value:1},
        {label:"退货", value:2},
        {label:"调价", value:3},
        {label:"折让", value:4},
        {label:"销退", value:5}
      ],
      operationTypeList: [
        {label:"补差", value:2},
        {label:"货损", value:1},
        {label:"补贴", value:3},
        {label:"召回", value:4},
        {label:"调价", value:5},
        {label:"折让", value:6},
        {label:"销退", value:7}
      ],
      statusOptions: [
        {label:"待付款", value:100},
        {label:"待收货", value:140},
        {label:"已完成", value:160},
        {label:"已关闭", value:170},
      ]
    };
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    // 复制
    copywxtap(txt) {
      let all = [];
      all.push(txt);
      this.copyContent = all.join(); //也可以直接写上等于你想要复制的内容
      var input = document.createElement("input"); // 直接构建input
      input.value = this.copyContent; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },

    // 保存回寄物流信息
    saveDelivery(){
      if (!this.oderList.deliveryCompany) {
        this.$message.warning("请填写物流公司");
        return;
      } 
      if (!this.oderList.deliveryNo) {
        this.$message.warning("请填写物流单号");
        return;
      }
      let data = {
        applyId:this.applyId,
        deliveryCompany:this.oderList.deliveryCompany,
        deliveryNo:this.oderList.deliveryNo
      }
      returnProduct(data).then((res) => {
        if (res.code === 200) {
          this.$message.success("提交成功,即将返回上一页");
          setTimeout(() => {
            this.$router.push("/user/Aftersales");
          }, 1500);
        }
      });
    }
  },
};
</script>
 
<style lang = "less" scoped>

.content-item {
  width: 100%;
  background: #ffffff;
  padding: 30px 30px 10px;
  position: relative;
  position: relative;
  .status-info {
    position: absolute;
    right: 0;
    top: 30px;
    padding: 8px;
    border-radius: 2px 0px 0px 2px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    background: #fef0df;
    color: #faa743;
  }
  .red-info {
    background: #ffd8db;
    color: #ff1a2e;
  }
  .green-info {
    background: #daf5eb;
    color: #26c487;
  }
  .table-item {
    display: flex;
    padding-bottom: 30px;
    text-align:left;
    img {
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }
    .table-info {
      font-size: 12px;
      font-weight: 400;
      color: #adafad;
      div {
        height: 20px;
      }
    }
    .table-title {
      font-size: 14px;
      font-family: "medium";
      font-weight: 500;
      color: #131313;
    }
  }
  .save-btn{
    display: inline-block;
    width:100px;
    height:30px;
    line-height: 30px;
    background: #26c487;
    border-radius:6px;
    color:#ffffff;
    cursor: pointer;
    text-align: center;
    float:right;
    margin-bottom: 10px;
  }
}
.add-box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "medium";
  font-weight: 500;
  color: #202d40;
}
.add-bg {
  width: 3px;
  height: 16px;
  background: #26c487;
  border-radius: 2px;
  margin-right: 11px;
}
.info {
  font-size: 14px;
  font-weight: 400;
  color: #202D40;
  margin-bottom: 20px;
  span {
    display: inline-block;
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height:32px;
    line-height: 32px;
  }
  .status {
    display: inline-block;
    font-family: "bold";
    color: #202d40;
  }
  
  .copy-btn {
    color: #26c487;
    line-height: 12px;
    cursor: pointer;
    margin-left: 10px;
  }

  .el-input{
    width: 200px;
  }
}

.bottom-box {
  text-align: right;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  font-family:'medium';
  color: #202D40;
  div {
    margin-bottom: 20px;
  }
  .bottom-num {
    display: inline-block;
    width: 150px;
  }
  .box-input {
    width: 100px;
    margin-left: 10px;
  }
}
.black {
  color: #202d40;
}
.red {
  color: #ff1a2e;
}
.green {
  color: #26c487;
}
.cup {
  cursor: pointer;
}
.bold {
  font-size: 20px;
  font-family: "bold";
  font-weight: bold;
}

.operation-box {
  text-align: right;
  margin: 40px 0;
  span {
    display: inline-block;
    width: 80px;
    height: 32px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #e7ebe7;
    font-size: 14px;
    color: #707070;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
  .agree-btn {
    background: #26c487;
    color: #ffffff;
  }
  :last-child {
    margin-left: 12px;
  }
}
.discounts {
  font-size: 12px;
  color: #faa743;
}

::v-deep .el-dialog {
  .el-dialog__header {
    background-color: #f7f8fa;
  }
  .el-dialog__title {
    font-size: 20px;
    font-family: "medium";
    color: #202d40;
  }
  textarea {
    width: 100%;
    height: 150px;
    background: #f7f8fa;
    border: none;
    outline: none;
    padding: 12px;
  }
  .el-input {
    width: 260px;
    margin-left: 10px;
  }
  .el-input__prefix {
    top: 8px;
  }
}

.reject-box {
  width: 500px;
  background: #ffe6e8;
  border-radius: 2px;
  border: 1px solid rgba(38, 196, 135, 0.2);
  font-size: 14px;
  color: #202d40;
  display: flex;
  align-items: flex-start;
  padding: 13px 10px;
  margin-bottom: 30px;
  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

.green-btn {
  display: inline-block;
  background: #26c487;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  padding: 7px 26px;
  margin-left: 24px;
}

.change-box {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 150%;
  height: 40px;
  position: absolute;
  bottom: 20px;
  z-index: 99;
  font-size: 14px;
  .el-input {
    color: #ff1a2e;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #9e9fab;
    &:first-child {
      margin-right: 20px;
    }
  }
  input {
    border: none;
    outline: none;
    width: 40px;
    height: 30px;
    text-align: center;
    margin: 0 10px;
    color: #ff1a2e;
    font-size: 20px;
  }
  .num {
    width: 26px;
    height: 26px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }
}


::v-deep .el-timeline-item__timestamp{
  position:absolute;
  left:-90px;
  top:-10px;
  color:#9E9FAB;
  text-align:right;
  line-height:15px;
}
::v-deep .el-timeline-item__content{
  display:flex;
  align-items:center;
  color:#2E323C;
  span{
    margin-left:40px;
  }
}
</style>